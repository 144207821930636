import React, { useEffect } from "react";
import classNames from "classnames/bind";
import "../util/reset.css";
import styles from "./ThreeJsTimePage.module.scss";

import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { rotateAboutPoint } from "../functions/ThreeJsFunctions";
// import { tick } from "../functions/ThreeJsFunctions";

const cx = classNames.bind(styles);

// const OrbitControls = oc(THREE);

const ThreeJsTimePage = () => {
  const size = { width: window.innerWidth, height: window.innerHeight };

  const scene = new THREE.Scene();
  const camera = new THREE.PerspectiveCamera(
    75,
    size.width / size.height,
    0.01,
    2000
  );
  const renderer = new THREE.WebGLRenderer({ antialias: true });

  // camera.position.x = 300;
  //   camera.position.y = 0;
  camera.position.z = 100;

  scene.add(camera);

  renderer.setSize(size.width, size.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

  renderer.setClearColor(new THREE.Color("#21282a"), 1);

  const clock = new THREE.Clock();

  const box_geometry = new THREE.BoxGeometry();
  const line_material = new THREE.LineBasicMaterial({ color: 0x555555 });

  const torus_geometry = new THREE.TorusGeometry(300, 150, 32, 1);
  const torus_material = new THREE.PointsMaterial({
    size: 1.5,
    color: 0x87a7ca,
  });
  const torus = new THREE.Points(torus_geometry, torus_material);
  const torus2 = new THREE.Points(
    new THREE.TorusGeometry(300, 150, 32, 200),
    torus_material
  );

  //   const stars = new THREE.

  const controls = new OrbitControls(camera, renderer.domElement);
  controls.minDistance = 1;
  controls.maxDistance = 2000;
  controls.target = new THREE.Vector3(0, 0, -100);
  controls.enableDamping = true;
  controls.dampingFactor = 0.1;
  //   controls.screenSpacePanning = false;

  //   scene.add(cube);
  // scene.add(torus);
  // scene.add(torus2);
  let lines = [];
  let pivots = [];
  useEffect(() => {
    for (let i = -30; i < 30; i++) {
      for (let j = -30; j < 30; j++) {
        lines.push({
          object: new THREE.Line(
            new THREE.BufferGeometry().setFromPoints([
              new THREE.Vector3(i * 10, j * 10 - 4, 0),
              new THREE.Vector3(i * 10, j * 10, 0),
              new THREE.Vector3(i * 10, j * 10, -2),
            ]),
            line_material
          ),
          x: 10 * i,
          y: 10 * j,
        });
        let circle = new THREE.Mesh(
          new THREE.CircleGeometry(4.5, 30),
          new THREE.MeshPhongMaterial({ color: 0xffffff, flatShading: true })
        );
        circle.translateX(10 * i);
        circle.translateY(10 * j);
        circle.translateZ(-2);
        scene.add(circle);
      }
    }

    lines.forEach((line, idx) => {
      scene.add(line.object);
    });
  }, []);

  //   renderer.render(scene, camera);

  const animate = () => {
    requestAnimationFrame(animate);

    // torus.rotation.z = clock.getElapsedTime() / 1;
    torus.rotation.z = (2 * Math.PI * clock.getElapsedTime()) / 60;

    lines.forEach((line) => {
      line.object.translateX(+line.x);
      line.object.translateY(+line.y);
      line.object.rotation.z = -(2 * Math.PI * clock.getElapsedTime()) / 1;
      // Math.sqrt(line.x * line.x + line.y * line.y) *
      //   0.01 *
      //   clock.getElapsedTime();
      line.object.translateX(-line.x);
      line.object.translateY(-line.y);
    });
    // cube.rotation.y += 0.01;
    controls.update();
    // camera.lookAt(new THREE.Vector3(3, 0.1, 0));
    renderer.render(scene, camera);
  };

  animate();

  useEffect(() => {
    window.addEventListener("resize", () => {
      size.width = window.innerWidth;
      size.height = window.innerHeight;
      // Update camera
      camera.aspect = size.width / size.height;
      camera.updateProjectionMatrix();

      // Update renderer
      renderer.setSize(size.width, size.height);
      renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    });
    document
      .getElementsByClassName("three-js-container")[0]
      .append(renderer.domElement);
  }, []);

  return (
    <div className={cx("wrapper") + " three-js-container"}>
      <div className={cx("frame")}>
        <h1>TIME</h1>
      </div>
    </div>
  );
};

export default ThreeJsTimePage;
